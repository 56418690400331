<template>
  <div class="script-card-container">
    <div class="left-container">
      <div
        style="
          width: 56px;
          height: 56px;
          background: #5fc367;
          color: #fff;
          border-radius: 8px;
          margin: 34px 0 14px 40px;
          font-size: 28px;
        "
      >
        <a-icon type="profile" style="margin-top: 14px" />
      </div>
      <div>
        <a-tag color="orange" style="margin: 0">{{ detail.module }}</a-tag>
      </div>
    </div>
    <div class="right-container">
      <!-- 更多操作 -->
      <span style="position: absolute; display: block; right: 6px; top: 0px; font-size: 18px;">
        <a-dropdown>
          <a><a-icon type="ellipsis" style="cursor: pointer"></a-icon></a>
          <a-menu slot="overlay">
            <a-menu-item>
              <edit-button v-permission="{action: 'ops.script.update', effect: 'disabled'}" @click="$emit('edit', detail.id)"></edit-button>
            </a-menu-item>
            <a-menu-item>
              <delete-button
              v-permission="{action: 'ops.script.delete', effect: 'disabled'}"
                @confirm="$emit('delete', detail.id)"
              ></delete-button>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
      <a-tooltip :title="detail.name">
        <div class="script-name">{{ detail.name }}</div>
      </a-tooltip>
      <a-tooltip :title="detail.description">
        <div class="script-description">
          {{ detail.description || '暂无描述' }}
        </div>
      </a-tooltip>
      <div>
        <a-button
          type="primary"
          v-permission="{action: 'ops.script_task.create', effect: 'disabled'}"
          style="
            width: 72px;
            height: 32px;
            position: absolute;
            bottom: 12px;
            left: 12px;
          "
          @click="
            $router.push({ name: 'ScriptExecute', params: { id: detail.id } })
          "
        >
          执行
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'

export default {
  name: 'ScriptCard',
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  components: {
    DeleteButton,
    EditButton
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="less">
.script-card-container {
  height: 156px;
  min-height: 156px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: #fff;
  display: flex;
  flex-wrap: nowrap;

  .left-container {
    border-right: 1px solid #d9d9d9;
    height: 100%;
    width: 135px;
    text-align: center;
  }

  .right-container {
    padding: 12px 24px 12px 16px;
    position: relative;
    height: 100%;
    flex: 1;
    & > div {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .script-name {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 20px;
      margin-bottom: 10px;
    }
    .script-description {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 17px;
    }
  }
}
</style>
