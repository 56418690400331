var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{staticClass:"script-input-drawer",attrs:{"destroy-on-close":true,"title":_vm.isUpdate ? '更新脚本' : '新增脚本',"visible":_vm.visible,"width":840},on:{"close":_vm.closeDrawer}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-form-model-item',{attrs:{"label":"脚本名称","prop":"name"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"版本号","prop":"version"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.version),callback:function ($$v) {_vm.$set(_vm.form, "version", $$v)},expression:"form.version"}})],1),_c('a-form-model-item',{attrs:{"label":"脚本描述","prop":"description"}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('a-form-model-item',{attrs:{"prop":"groups","label":"所属分组"}},[_c('group-select',{attrs:{"source-type":"script"},model:{value:(_vm.form.groups),callback:function ($$v) {_vm.$set(_vm.form, "groups", $$v)},expression:"form.groups"}})],1),_c('a-form-model-item',{attrs:{"prop":"module","label":"脚本类型"}},[_c('a-radio-group',{attrs:{"options":_vm.moduleOptions},model:{value:(_vm.form.module),callback:function ($$v) {_vm.$set(_vm.form, "module", $$v)},expression:"form.module"}})],1),_c('a-form-model-item',{attrs:{"prop":"content","label":"脚本内容"}},[_c('code-mirror',{model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('a-form-model-item',{staticStyle:{"margin-bottom":"0"},attrs:{"wrapper-col":{ span: 20, offset: 4 }}},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确定 ")]),_c('a-button',{on:{"click":_vm.closeDrawer}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }