<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '更新脚本' : '新增脚本'"
    :visible="visible"
    :width="840"
    @close="closeDrawer"
    class="script-input-drawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="脚本名称" prop="name">
        <a-input
          v-model="form.name"
          :max-length="128"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="版本号" prop="version">
        <a-input
          v-model="form.version"
          :max-length="128"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="脚本描述" prop="description">
        <a-input v-model="form.description" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item prop="groups" label="所属分组">
        <group-select
          v-model="form.groups"
          source-type="script"
        ></group-select>
      </a-form-model-item>
      <!-- <a-form-model-item prop="script_souce" label="脚本来源">
        <a-radio-group :options="souceOptions" v-model="form.script_souce"/>
      </a-form-model-item> -->
      <a-form-model-item prop="module" label="脚本类型">
        <a-radio-group :options="moduleOptions" v-model="form.module"/>
      </a-form-model-item>
      <a-form-model-item prop="content" label="脚本内容">
        <code-mirror v-model="form.content"></code-mirror>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button  type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import GroupSelect from '@/components/select/GroupSelect'
import CodeMirror from '@/components/CodeMirror'
import { createScript, updateScript, getScript } from '@/api/script'

export default {
  name: 'InputDrawer',
  components: {
    GroupSelect,
    CodeMirror
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      souceOptions: [
        { label: '手工输入', value: 'input', disabled: false },
        { label: '脚本克隆', value: 'clone', disabled: true },
        { label: '本地脚本', value: 'local', disabled: true }
      ],
      moduleOptions: [
        { label: 'Shell', value: 'shell' }
        // { label: 'PowerShell', value: 'powershell' },
        // { label: 'Python', value: 'python' }
      ],
      form: {
        name: '',
        version: '',
        description: '',
        groups: undefined,
        module: 'shell',
        content: ''
      },
      rules: {
        name: [
          {
            message: '请输入脚本名称',
            required: true,
            trigger: 'blur'
          }
        ],
        version: [
          {
            message: '请输入版本号',
            required: true,
            trigger: 'blur'
          }
        ],
        groups: [
          {
            message: '请选择脚本分类',
            required: true,
            trigger: 'change'
          }
        ],
        script_souce: [
          {
            message: '请选择脚本来源',
            required: true,
            trigger: 'change'
          }
        ],
        module: [
          {
            message: '请选择脚本类型',
            required: true,
            trigger: 'change'
          }
        ],
        content: [
          {
            message: '请输入脚本内容',
            required: true,
            trigger: 'change'
          }
        ]
      },
      scriptId: ''
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.scriptId = id
        this.isUpdate = true
        getScript(id).then(res => {
          const data = res.data
          this.form.name = data.name
          this.form.description = data.description
          this.form.version = data.version
          this.form.module = data.module
          this.form.content = data.content
          this.form.groups = data.groups
        }).finally(() => {
          this.visible = true
        })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        name: '',
        version: '',
        description: '',
        groups: undefined,
        module: 'shell',
        content: ''
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            group_ids: this.form.groups.map(group => group.id),
            content: this.form.content,
            module: this.form.module,
            description: this.form.description,
            version: this.form.version
          }
          if (this.isUpdate) {
            updateScript(this.scriptId, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
          } else {
            createScript(params).then(res => {
              this.$message.success(res.message)
              this.$emit('ok')
              this.closeDrawer()
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="less">
.script-input-drawer {
  .ant-drawer-title {
    font-size: 18px;
    font-weight: 500;
    color: #096DD9;
    line-height: 25px;
  }
}
</style>
