<template>
  <div class="script-list-container">
    <a-layout style="background: #ffffff">
      <!-- 群组 -->
      <template v-if="groupTreeFunc">
        <a-tooltip>
        <template #title>
          {{ displayGroup ? '隐藏群组树' : '显示群组树' }}
        </template>
        <a-icon
          :type="treeDisplayIcon"
          @click="displayGroup = !displayGroup"
          style="
            font-size: 14px;
            color: #0a6dd9;
            cursor: pointer;
            position: absolute;
            top: 24px;
            left: -8px;
            background: #f0f2f5;
            padding: 6px 4px 6px 8px;
            z-index: 99;
            border-radius: 8px;
          "
        ></a-icon>
        </a-tooltip>
        <a-layout-sider
          v-if="displayGroup"
          style="
            border-right: 1px solid #e8e8e8;
            background: #fff;
            position: relative;
            min-height: calc(100vh - 158px);
            max-width: 214px;
            min-width: 214px;
            width: 214px;
            padding: 8px;
          "
        >
          <div
            style="
              font-size: 18px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
              line-height: 25px;
              margin-bottom: 8px;
              padding: 16px 0 0 20px;
            "
          >
            脚本仓库分类
          </div>
          <group-tree
            :create-func="groupTreeFunc.createFunc"
            :delete-func="groupTreeFunc.deleteFunc"
            :get-func="groupTreeFunc.getFunc"
            :get-list-func="groupTreeFunc.getListFunc"
            :update-func="groupTreeFunc.updateFunc"
            :canOperate="true"
            @select="getGroupTable"
            :apiPermission="groupApiPermission"
          ></group-tree>
        </a-layout-sider>
      </template>

      <a-layout-content style="padding: 24px">
        <div
          style="
            font-size: 18px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 25px;
            margin-bottom: 16px;
          "
        >
          脚本仓库
        </div>

        <div style="display: flex; justify-content: space-between; margin-bottom: 24px">
          <div>
            <a-input-search
              v-model="searchText"
              style="width: 350px; height: 32px"
              placeholder="请输入查询关键字"
              @search="onSearch"
            ></a-input-search>
          </div>
          <a-space size="large" style="">
            <a-button
              type="primary"
              icon="plus"
              style="width: 106px; height: 32px"
              @click="$refs.inputDrawer.show()"
              v-permission="{action: 'ops.script.create', effect: 'disabled'}"
            >
              新增脚本
            </a-button>
            <a-button
              icon="reload"
              style="width: 85px; height: 32px"
              @click="fetch()"
            >
              刷新
            </a-button>
          </a-space>
        </div>

        <a-spin :spinning="spinning">
          <template v-if="scriptList.length">
            <a-row :gutter="16">
              <a-col
                :md="24"
                :lg="12"
                :xl="8"
                v-for="(item, index) in scriptList"
                :key="index"
                style="margin-bottom: 16px"
              >
                <script-card :detail="item" @delete="confirm" @edit="id => $refs.inputDrawer.show(id)"></script-card>
              </a-col>
            </a-row>
            <div style="height: 32px">
              <a-pagination
                :current="fetchParams.page"
                :page-size="fetchParams.page_size"
                :page-size-options="['12', '24', '36', '48']"
                :show-size-changer="true"
                :show-total="t => `共 ${t} 条`"
                :total="total"
                @change="
                  page => {
                    fetchParams.page = page
                    fetch()
                  }
                "
                @showSizeChange="
                  (_, pageSize) => {
                    fetchParams.page = 1
                    fetchParams.page_size = pageSize
                    fetch()
                  }
                "
                style="float: right"
              ></a-pagination>
            </div>
          </template>
          <div v-else style="height: 320px; position: relative; overflow: hidden;">
            <empty-component
              :imgUrl="require('@/assets/images/info_empty.png')"
              :body-style="{
                height: '220px',
                marginTop: '28px'
              }"
              :description-style="{
                marginTop: '-12px',
                marginRight: '8px',
                color: 'rgba(0,0,0,.55)'
              }"
              description="暂无数据"
            >
            </empty-component>
          </div>
        </a-spin>
      </a-layout-content>
    </a-layout>

    <input-drawer
      ref="inputDrawer"
      @ok="() => {
        fetchParams = {
          page: 1,
          page_size: 12
        }
        searchText = ''
        fetch()
      }"
    ></input-drawer>
  </div>
</template>

<script>
import inputDrawer from './modules/InputDrawer'
import {
  updateScriptGroup,
  deleteScriptGroup,
  getScriptGroupList,
  createScriptGroup,
  getScriptList,
  deleteScript
} from '@/api/script'
import { updateCurrentPage, hasPermission } from '@/utils'
import GroupTree from '@/components/GroupTree'
import ScriptCard from './modules/ScriptCard'
import EmptyComponent from '@/components/EmptyComponent.vue'

export default {
  name: 'ScriptList',
  components: {
    inputDrawer,
    GroupTree,
    ScriptCard,
    EmptyComponent
  },
  data () {
    return {
      spinning: false,
      displayGroup: true,
      fetchParams: {
        page: 1,
        page_size: 12
      },
      total: 0,
      searchText: '',
      scriptList: [],
      groupApiPermission: {
        create: 'ops.script_group.create',
        edit: 'ops.script_group.update',
        delete: 'ops.script_group.delete'
      }
    }
  },
  computed: {
    treeDisplayIcon () {
      return this.displayGroup ? 'left' : 'right'
    },
    groupTreeFunc () {
      if (hasPermission('ops.script_group.view')) {
        return {
          createFunc: createScriptGroup,
          deleteFunc: deleteScriptGroup,
          getListFunc: getScriptGroupList,
          updateFunc: updateScriptGroup
        }
      } else return null
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch (update = false) {
      this.spinning = true
      if (update) {
        this.fetchParams.page = updateCurrentPage(
          this.total,
          this.fetchParams.page,
          this.fetchParams.page_size
        )
      }
      getScriptList(this.fetchParams).then(res => {
        this.scriptList = res.data.data
        this.total = res.data.total
      }).finally(() => { this.spinning = false })
    },
    onSearch (v) {
      this.fetchParams = {
        page: 1,
        page_size: 12,
        name: v
      }
      this.fetch()
    },
    getGroupTable (v) {
      this.fetchParams.page = 1
      this.fetchParams.group_id = v
      this.fetch()
    },
    async confirm (id = '') {
      if (id) {
        await deleteScript(id).then(res => {
          this.$message.success(res.message)
        })
        this.fetch(true)
      } else this.fetch()
    }
  }
}
</script>

<style lang="less">
.script-list-container {
  // height: calc(100vh - 158px);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
}
</style>
